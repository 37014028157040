import React from 'react';
import './contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h1 className="contact-title">Get in Touch</h1>
      <div className="form-container">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeQ8Qds-vRTMpqnp-qRhB_aNcajow26ym8-T9kdtLN9aGI76w/viewform?embedded=true"
          width="100%"
          height="900"  /* Set a fixed height */
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Contact Form"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default Contact;
