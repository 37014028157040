import React from 'react';
import "./logo.css"

const Logo = () => {
  return (
    <div className='logo-container'> {/* Added class logo-container */}
      <div className='logo'>
      <img src="/images/Logo.png" alt="logo" className="section-image" />
      </div>
    </div>
  );
};

export default Logo;