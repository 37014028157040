import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 HM Photography</p>
        {/* Instagram link with icon */}
        <a href="https://www.instagram.com/hmarchphotos" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="instagram-icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
