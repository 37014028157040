import React from 'react';
import './investment.css';

const Investment = () => {
  return (
    <div className="investment-container">
      <h1 className="investment-title">Investment</h1>

      {/* Senior Photos */}
      <section className="investment-section">
        <div className="package-info">
          <h2>Senior Photos</h2>
          <div className="package-content">
            <img src="/images/IMG_6143.jpg" alt="Senior Photos" className="section-image" />
            <div className="text-content">
              <div className="package">
                <h3>Package 1</h3>
                <p>Two hour shoot, as many outfits as you like! Cap and gown, sports, marching band, etc! Whatever you want! 50+ edited photos.</p>
                <p className="price">$300</p>
              </div>
              <div className="package">
                <h3>Package 2</h3>
                <p>1.5 hour shoot, 3 outfits! Cap and gown, headshot, sports, marching band, etc! 30+ edited photos!</p>
                <p className="price">$250</p>
              </div>
              <div className="package">
                <h3>Package 3</h3>
                <p>1 hour shoot, 2 outfits! Cap and gown and a headshot. 20+ edited photos!</p>
                <p className="price">$125</p>
              </div>
              <div className="package">
                <h3>Package 4</h3>
                <p>Quick 30-minute session of cap and gown photos! 10+ edited photos!</p>
                <p className="price">$75</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Family Photos */}
      <section className="investment-section">
        <div className="package-info">
          <h2>Family Photos</h2>
          <div className="package-content">
            <img src="/images/IMG_4906.jpg" alt="Family Photos" className="section-image" />
            <div className="text-content">
              <div className="package">
                <h3>Standard</h3>
                <p>1-hour shoot, 1 location, 40+ edited images.</p>
                <p className="price">$250</p>
              </div>
              <div className="package">
                <h3>Extended</h3>
                <p>2-hour session, multiple locations, 80+ edited images.</p>
                <p className="price">$400</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Engagement Photos */}
      <section className="investment-section">
        <div className="package-info">
          <h2>Engagement Photos</h2>
          <div className="package-content">
            <img src="/images/IMG_6841.jpg" alt="Engagement" className="section-image" />
            <div className="text-content">
              <div className="package">
                <h3>Standard</h3>
                <p>2-hour session, 2 locations, 40+ edited images.</p>
                <p className="price">$300</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Events */}
      <section className="investment-section">
        <div className="package-info">
          <h2>Events</h2>
          <div className="package-content">
            <img src="/images/IMG_4056.jpeg" alt="Events" className="section-image" />
            <div className="text-content">
              <div className="package">
                <h3>Hourly Rate</h3>
                <p>Photography for special events, hourly rate includes all edited photos delivered digitally.</p>
                <p className="price">$150/hour</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Weddings */}
      <section className="investment-section">
        <div className="package-info">
          <h2>Weddings</h2>
          <div className="package-content">
            <img src="/images/IMG_3948.jpeg" alt="Weddings" className="section-image" />
            <div className="text-content">
              <div className="package">
                <p>Please reach out to set up a time to discuss packages!</p>
                <p className="price">$900-$2000</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="contact-section">
        <h3>Let's Work Together!</h3>
        <p>If there's not a package you want, contact me! We can work something out! For inquiries, please fill out the <a href="#contact-form">contact form</a>.</p>
      </div>
    </div>
  );
};

export default Investment;
