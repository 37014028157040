import React from 'react';
import './about.css'; // Ensure the CSS file path is correct

const About = () => {
  return (
    <div className='container'>
      <div className='text-container'>
        <h2>About Me</h2>
        <p>
          Hi! I'm Haylee Marchesani, a senior in Economics and Communications at Iowa State. I have a deep passion for photography.
          Whether it's capturing your senior year, your engagement, or a baby announcement, I strive to help you
          preserve those precious moments. My free time consists of spending time with my wonderful husband! Whether that's baking together or playing ultimate frisbee!
           Let's connect over coffee or tea to discuss how I can help you cherish your special moments.
        </p>
      </div>
      <div className='image-container'>
      <img src="/images/IMG_3267.jpg " alt="Gallery 1" className="gallery-image" />    
       </div>
    </div>
  );
};

export default About;
