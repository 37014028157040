import React, { useState } from 'react';
import './menu.css';

function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="hamburger"></div>
      </div>
      <nav className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          {/* <li><a href="/gallery">Gallery</a></li> */}
          <li><a href="/investment">Investment</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </nav>
      <nav className="top-menu">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          {/* <li><a href="/gallery">Gallery</a></li> */}
          <li><a href="/investment">Investment</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default Menu;
