import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './home';
import About from './about';
// import Gallery from './gallery';
import Investment from './investment';
import Contact from './contact';
import Menu from './menu'; // Assuming your Menu component file is named Menu.js
import './App.css';
import Footer from "./footer";
import Logo from "./logo";
import { inject } from '@vercel/analytics';

const App = () => {
  useEffect(() => {
    // Initialize web analytics
    inject();
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Logo /> {/* Render the Logo component */}
        <Menu /> {/* Include the Menu component */}
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/gallery" element={<Gallery />} /> */}
            <Route path="/investment" element={<Investment />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer /> {/* Render the Footer component */}
      </div>
    </Router>
  );
};

export default App;
